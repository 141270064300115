<template>
    <div class="page_404">
        <div class="container">
            <div class="row">
                <div class="text-center mt-4 float-left">
                    <!-- <img class="img-fluid h-12 logo-top pl-5" src="./../assets/images/header_logo.png" alt="automatisera"> -->
                    <span class="text-2xl font-black text-gray-800">B2B Portal</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="w-96 mx-auto">
                        <img src="./../assets/images/illus/error.svg" class="img-fluid" alt="error notice">
                        <div class="text-xl text-center link-theme-color font-bold md:-mt-5 text-gray-900">
                            {{ $t('notFoundText') }}
                        </div>
                        <div class="flex justify-center mt-5">
                            <router-link :to="{ name: 'login' }" class="btn hover:text-white btn-custom btn-slider-b btn-wide btn-lg px-5">
                                <span>{{ $t('backHome') }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name : 'not-found',
        methods : {
            redirectToPrevious () {
                window.history.go(-1)
            }
        }
    }
</script>

<style lang="scss" scoped>
/*======================
    404 page
=======================*/
.page_404{ 
    padding: 40px 0; 
    background:#fff; 
    // font-family: 'Arvo', serif
}
.page_404  img{
    width: 100%;
}
.four_zero_four_bg{
    // background-image: url('./../assets/images/dribble.gif');
    height: 400px;
    background-position: center;
}
.four_zero_four_bg h1{
    font-size:80px;
}
.four_zero_four_bg h3{
    font-size:80px;
}
.link_404{
    color:#fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404{ 
    margin-top: -50px;
}
</style>